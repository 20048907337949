import React, { Component } from 'react'
import Rotas from '../src/routes';

export default class App extends Component {
  render() {
    return (
      <div>
        <Rotas/>
      </div>
    )
  }
}